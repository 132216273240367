import { FormControl, ListItemIcon, MenuItem, Select } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HeaderProps } from "react-table";
import { WorkQueue } from "../../../../../store/main/work-queue/workQueueSlice";

interface Option {
  stateName: string;
  color: string;
}

const JobStateFlatColumnFilter: React.FC<HeaderProps<WorkQueue>> = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const { t } = useTranslation();

  const options: Option[] = useMemo(() => {
    const options = new Array<Option>();
    preFilteredRows.forEach((row) => {
      if (!options.some((o) => o.stateName === row.original.taskStateName)) {
        options.push({
          stateName: row.original.taskStateName!,
          color: row.original.taskStateColor ?? "transparent",
        });
      }
    });
    return [...options];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFilteredRows]);

  return (
    <FormControl fullWidth>
      <Select
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        displayEmpty
        variant="standard"
      >
        <MenuItem value="">{t("All")}</MenuItem>
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option.stateName}
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <ListItemIcon style={{ minWidth: 0, alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: option.color,
                  marginRight: "0.2rem",
                  width: "0.3rem",
                  height: "1.4rem",
                }}
              ></div>
            </ListItemIcon>

            {t(option.stateName)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default JobStateFlatColumnFilter;
