type ThemeType = "CORE" | "AVEVA" | "DIH";

declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_WEBSITE_NAME: string;
  REACT_APP_MES_API_URL: string;
  REACT_APP_MQTT_URL: string;
  REACT_APP_MQTT_USER: string;
  REACT_APP_MQTT_PASSWORD: string;
  REACT_APP_MQTT_PREFIX: string;
  REACT_APP_ICONS_URL: string;
  REACT_APP_THEME_TYPE: ThemeType;
  REACT_APP_PRODUCT_VERSION: string;
  REACT_APP_IDLE_TIMEOUT: number;
};
export const env: EnvType = { ...process.env, ...window["env"] };
