import { FormControl, MenuItem, Select } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HeaderProps } from "react-table";
import { WorkQueue } from "../../../../../store/main/work-queue/workQueueSlice";
import { useAppSelector } from "../../../../../store/hooks";
import { selectSelectedAsset } from "../../../../../store/user/assetSelectionSlice";

const ScheduledAssetNameFlatColumnFilter: React.FC<HeaderProps<WorkQueue>> = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const { t } = useTranslation();
  const selectedAsset = useAppSelector(selectSelectedAsset);

  const options: string[] = useMemo(() => {
    const options = new Array<string>();
    selectedAsset && options.push(selectedAsset.name!);
    preFilteredRows.forEach((row) => {
      if (!options.some((o) => o === row.original.scheduledAssetName)) {
        options.push(row.original.scheduledAssetName!);
      }
    });
    return [...options];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFilteredRows]);

  React.useEffect(() => {
    setFilter([selectedAsset?.name]);
  }, [selectedAsset]);

  return (
    <FormControl fullWidth>
      <Select
        value={filterValue || []}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        displayEmpty
        variant="standard"
        multiple
      >
        <MenuItem value="">{t("All")}</MenuItem>
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option}
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {t(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ScheduledAssetNameFlatColumnFilter;
