import { Middleware } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import { PersistConfigKey, RootState } from "..";
import GridName from "../../helpers/enums/gridName";
import { LocalStorageKey } from "../../helpers/localStorageKeys";
import { getFromLocalStorage } from "../../services/localStorageService";
import { refreshToken } from "../../services/main/authenticationService";
import { TasksAssetGroupService as ManufacturingExecutionTasksAssetGroupService } from "../../services/sockets/hub-services/manufacturing-execution/tasksAssetGroupService";
import { TasksAssetGroupService as ProductionPlanningTasksAssetGroupService } from "../../services/sockets/hub-services/production-planning/tasksAssetGroupService";
import { UtilizationEventsAssetGroupService } from "../../services/sockets/hub-services/utilization/utilizationEventsAssetGroupService";
import { loadCustomGridComponentsAsync } from "../common/customGridComponentsSlice";
import { defaultAssetDialogOpened } from "../common/dialogs/defaultAssetSlice";
import { loadDefaultLayoutConfiguration } from "../common/gridsSlice";
import { loadDefaultTablesConfiguration } from "../common/tablesSlice";
import { loadNestedAssetsAsync } from "../definitions/assetsSlice";
import { loadNestedAttributeGroupsAsync } from "../definitions/attributeGroupsSlice";
import { loadUtilizationExecsAsync } from "../main/util-execs/utilExecsSlice";
import { loadUtilEventsForCurrentShiftAsync } from "../main/utilization-events/utilEventsSlice";
import {
  logOnAssetAsync,
  selectSelectedAssetId,
} from "../user/assetSelectionSlice";
import { logInAsync, logOutAsync } from "../user/authSlice";
import { defaultAssetSelected } from "../user/defaultAssetSlice";
import { AssetsAllService } from "../../services/sockets/hub-services/utilization/assetsAllService";
import { AssetsAssetGroupService } from "../../services/sockets/hub-services/utilization/assetsAssetGroupService";
import { ProductionEventsAssetGroupService } from "../../services/sockets/hub-services/manufacturing-execution/productionEventsAssetGroupService";
import { WorkQueueAssetGroupService } from "../../services/sockets/hub-services/manufacturing-execution/workQueueAssetGroupService";

const assetsAllService = new AssetsAllService();
const assetsAssetGroupService = new AssetsAssetGroupService();
const utilizationEventsAssetGroupService =
  new UtilizationEventsAssetGroupService();
const manufacturingExecutionTasksAssetGroupService =
  new ManufacturingExecutionTasksAssetGroupService();
const workQueueAssetGroupService = new WorkQueueAssetGroupService();
const productionEventsAssetGroupService =
  new ProductionEventsAssetGroupService();
const productionPlanningTasksAssetGroupService =
  new ProductionPlanningTasksAssetGroupService();

const initAppState = async (dispatch, getState, logOnEnt: boolean = true) => {
  await assetsAllService.connect(dispatch, getState);
  assetsAllService.subscribe(dispatch, getState);

  await assetsAssetGroupService.connect(dispatch, getState);
  assetsAssetGroupService.subscribe(dispatch, getState);

  await utilizationEventsAssetGroupService.connect(dispatch, getState);
  utilizationEventsAssetGroupService.subscribe(dispatch, getState);

  await manufacturingExecutionTasksAssetGroupService.connect(
    dispatch,
    getState
  );
  manufacturingExecutionTasksAssetGroupService.subscribe(dispatch, getState);

  await workQueueAssetGroupService.connect(dispatch, getState);
  workQueueAssetGroupService.subscribe(dispatch, getState);

  await productionEventsAssetGroupService.connect(dispatch, getState);
  productionEventsAssetGroupService.subscribe(dispatch, getState);

  await productionPlanningTasksAssetGroupService.connect(dispatch, getState);
  productionPlanningTasksAssetGroupService.subscribe(dispatch, getState);

  dispatch(loadNestedAssetsAsync());
  dispatch(loadUtilizationExecsAsync());
  dispatch(loadUtilEventsForCurrentShiftAsync());
  await dispatch(loadNestedAttributeGroupsAsync());

  //handle default tables configurations
  dispatch(loadDefaultTablesConfiguration() as any);
  //handle default layout configurations
  await dispatch(loadCustomGridComponentsAsync());
  dispatch(loadDefaultLayoutConfiguration(GridName.PRODUCTION) as any);
  dispatch(loadDefaultLayoutConfiguration(GridName.OVERVIEW) as any);

  //handle default asset
  const defaultAssetId = getFromLocalStorage(LocalStorageKey.DEFAULT_ASSET_ID);
  const defaultAssetSourceStack = getFromLocalStorage(
    LocalStorageKey.DEFAULT_ASSET_SOURCESTACK
  )?.split(",");

  if (defaultAssetId === null || defaultAssetId === undefined) {
    dispatch(defaultAssetDialogOpened());
  } else {
    if (defaultAssetId && defaultAssetId !== String(-1)) {
      dispatch(
        defaultAssetSelected({
          defaultAssetId: defaultAssetId,
          defaultAssetSourceStack: defaultAssetSourceStack,
        })
      );
      if (logOnEnt) {
        dispatch(
          logOnAssetAsync({
            assetId: defaultAssetId,
            sourceStack: defaultAssetSourceStack,
          }) as any
        );
      }
    }
  }
};

export const signalRMiddleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === logOnAssetAsync.fulfilled.type) {
      const selectedAssetId = selectSelectedAssetId(getState() as RootState);
      if (selectedAssetId) {
        manufacturingExecutionTasksAssetGroupService.leaveAssetGroup(
          selectedAssetId
        );
        workQueueAssetGroupService.leaveAssetGroup(selectedAssetId);
        productionEventsAssetGroupService.leaveAssetGroup(selectedAssetId);
        productionPlanningTasksAssetGroupService.leaveAssetGroup(
          selectedAssetId
        );
        utilizationEventsAssetGroupService.leaveAssetGroup(selectedAssetId);
        assetsAssetGroupService.leaveAssetGroup(selectedAssetId);
      }
      if (action.payload.assetId) {
        manufacturingExecutionTasksAssetGroupService.joinAssetGroup(
          action.payload.assetId
        );
        workQueueAssetGroupService.joinAssetGroup(action.payload.assetId);
        productionEventsAssetGroupService.joinAssetGroup(
          action.payload.assetId
        );
        productionPlanningTasksAssetGroupService.joinAssetGroup(
          action.payload.assetId
        );
        utilizationEventsAssetGroupService.joinAssetGroup(
          action.payload.assetId
        );
        assetsAssetGroupService.joinAssetGroup(action.payload.assetId);
      }
    }
    // if (action.type === runTaskAsync.fulfilled.type) {
    //   const runnedTaskId = selectRunnedTaskId(getState() as RootState);
    //   if (runnedTaskId) {
    //     tasksService.leaveTaskGroup(runnedTaskId);
    //   }
    //   if (action.payload.taskId) {
    //     tasksService.joinTaskGroup(action.payload.taskId);
    //   }
    // }
    // if (action.type === resumeTaskAsync.fulfilled.type) {
    //   const runnedTaskId = selectRunnedTaskId(getState() as RootState);
    //   if (runnedTaskId) {
    //     tasksService.leaveTaskGroup(runnedTaskId);
    //   }
    //   if (action.payload.taskId) {
    //     tasksService.joinTaskGroup(action.payload.taskId);
    //   }
    // }
    if (action.type === logInAsync.fulfilled.type) {
      await initAppState(dispatch, getState);
    }
    if (action.type === REHYDRATE && action.key === PersistConfigKey.User) {
      const token = action?.payload?.auth?.details?.token;
      // const username = action?.payload?.auth?.details?.username;
      const assetId = action?.payload?.selectedAsset?.selectedAssetId;
      const assetSourceStack =
        action?.payload?.selectedAsset?.selectedAssetSourceStack;

      if (token) {
        const result = await refreshToken(false, "console");

        if (result?.token) {
          await initAppState(dispatch, getState, false);
          action.payload.auth.details.token = result.token;

          if (assetId && assetSourceStack && assetId !== String(-1)) {
            manufacturingExecutionTasksAssetGroupService.joinAssetGroup(
              assetId
            );
            workQueueAssetGroupService.joinAssetGroup(assetId);
            productionEventsAssetGroupService.joinAssetGroup(assetId);
            productionPlanningTasksAssetGroupService.joinAssetGroup(assetId);
            utilizationEventsAssetGroupService.joinAssetGroup(assetId);
            assetsAssetGroupService.joinAssetGroup(assetId);
            dispatch(
              logOnAssetAsync({ assetId, sourceStack: assetSourceStack }) as any
            );
          }
        } else {
          //COULDNT REFRESH => REDIRECT TO /LOGIN
          action.payload.auth.details.token = null;
        }
      }
    }
    if (
      action.type === logOutAsync.fulfilled.type ||
      action.type === logOutAsync.rejected.type
    ) {
      assetsAllService.disconnect();

      assetsAssetGroupService.disconnect();

      utilizationEventsAssetGroupService.disconnect();

      manufacturingExecutionTasksAssetGroupService.disconnect();

      workQueueAssetGroupService.disconnect();

      productionEventsAssetGroupService.disconnect();

      productionPlanningTasksAssetGroupService.disconnect();
    }
    return next(action);
  };

export default signalRMiddleware;
