import { combineReducers } from "@reduxjs/toolkit";
import tasksReducer from "./tasksSlice";
import productionEventsReducer from "./productionEventsSlice";
import workQueueReducer from "./workQueueSlice";

export default combineReducers({
  tasks: tasksReducer,
  productionEvents: productionEventsReducer,
  workQueue: workQueueReducer,
});
