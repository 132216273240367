import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  TaskCancelledSocketDto,
  TaskReleasedSocketDto,
  TaskRequeuedSocketDto,
  TaskRestoredSocketDto,
} from "../../../services/sockets/hub-services/production-planning/tasksAssetGroupService";

export interface TasksState {
  connectionStatus: HubConnectionState;
}

export const initialState: TasksState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const tasksSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    tasksConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    taskReleasedSocketEvent: (
      state,
      action: PayloadAction<TaskReleasedSocketDto>
    ) => {},
    taskCancelledSocketEvent: (
      state,
      action: PayloadAction<TaskCancelledSocketDto>
    ) => {},
    taskRestoredSocketEvent: (
      state,
      action: PayloadAction<TaskRestoredSocketDto>
    ) => {},
    taskRequeuedSocketEvent: (
      state,
      action: PayloadAction<TaskRequeuedSocketDto>
    ) => {},
  },
});

export const {
  tasksConnectionStatusChanged,
  taskReleasedSocketEvent,
  taskCancelledSocketEvent,
  taskRestoredSocketEvent,
  taskRequeuedSocketEvent,
} = tasksSlice.actions;

export const selectProductionPlanningTasksConnectionStatus = createSelector(
  (state: RootState) =>
    state.sockets.manufacturingExecution.tasks.connectionStatus,
  (connectionStatus) => connectionStatus
);

export default tasksSlice.reducer;
