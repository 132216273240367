import { Dialog, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import "simplebar-react/dist/simplebar.min.css";
import { env } from "../../env";
import { useAppDispatch } from "../../store/hooks";
import { logOutAsync } from "../../store/user/authSlice";

interface DialogIdleDetectorComponentProps {}

const DialogIdleDetectorComponent: React.FC<
  DialogIdleDetectorComponentProps
> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [timeToLogout, setTimeToLogout] = useState<number>(300);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    await dispatch(logOutAsync());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      open && setTimeToLogout((timeToLogout) => timeToLogout - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [open]);

  const handleOnIdle = (event) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatDuration = (countSec: number) => {
    const minutes = Math.floor(countSec / 60.0);
    const seconds = countSec - minutes * 60;
    return `${minutes}m ${seconds}s`;
  };

  const handleOnActive = (event) => {};

  const handleOnAction = (e) => {};

  useIdleTimer({
    timeout:
      env.REACT_APP_IDLE_TIMEOUT > 0
        ? 1000 * env.REACT_APP_IDLE_TIMEOUT
        : undefined,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    disabled: env.REACT_APP_IDLE_TIMEOUT < 0,
  });

  timeToLogout < 0 && handleLogout();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="idle detector"
      aria-describedby="idle detector"
      keepMounted={false}
    >
      <DialogTitle id="idle detector-title">
        {t("Too long idle time")}
      </DialogTitle>
      <DialogContent>
        {t("Due to inactivity, you will be logged out within 5 minutes")}
      </DialogContent>
      <DialogActions>
        <Button color="success" variant="contained" onClick={handleClose}>
          {t("Remaining time to resume work")}: {formatDuration(timeToLogout)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogIdleDetectorComponent;
