import { TFunction } from "i18next";
import { FilterValue, Row } from "react-table";

export function filterMultiSelect<T extends object>(
  rows: Array<Row<T>>,
  id: Array<string>,
  filterValue: FilterValue,
  t: TFunction
) {
  return filterValue.some((f) => f === "")
    ? rows
    : rows.filter((row) => {
        const rowValue = row.values[id[0]];
        return filterValue?.some((v: any) => v === rowValue);
      });
}
