import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { getWorkQueueByTaskId } from "../../../services/main/tasksService";
import { getTaskStateById } from "../../../services/main/taskStatesService";
import {
  taskPausedSocketEvent,
  taskRegisteredBadProductionSocketEvent,
  taskRegisteredGoodProductionSocketEvent,
  taskStartedSocketEvent,
} from "../../sockets/manufacturing-execution/tasksSlice";
import {
  taskAddedToWorkQueueSocketEvent,
  taskRemovedFromWorkQueueSocketEvent,
} from "../../sockets/manufacturing-execution/workQueueSlice";
import {
  loadWorkQueueAsync,
  quantityBadOnTaskChanged,
  quantityGoodOnTaskChanged,
  taskAddedToWorkQueue,
  taskRemovedFromWorkQueue,
  taskStateChanged,
} from "./workQueueSlice";
import { logOnAssetAsync } from "../../user/assetSelectionSlice";

const workQueueListener = createListenerMiddleware();

workQueueListener.startListening({
  actionCreator: taskRegisteredGoodProductionSocketEvent,
  effect: async (action, listenerApi) => {
    const { taskId, newTaskQuantity } = action.payload;
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(
      quantityGoodOnTaskChanged({ taskId, quantity: newTaskQuantity })
    );
  },
});

workQueueListener.startListening({
  actionCreator: taskRegisteredBadProductionSocketEvent,
  effect: async (action, listenerApi) => {
    const { taskId, newTaskQuantity } = action.payload;
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(
      quantityBadOnTaskChanged({ taskId, quantity: newTaskQuantity })
    );
  },
});

workQueueListener.startListening({
  matcher: isAnyOf(taskStartedSocketEvent, taskPausedSocketEvent),
  effect: async (action, listenerApi) => {
    const { taskId, taskStateId } = action.payload;
    const taskState = await getTaskStateById(taskStateId);
    listenerApi.cancelActiveListeners();
    taskState &&
      listenerApi.dispatch(
        taskStateChanged({
          taskId,
          taskStateName: taskState.name!,
          taskStateColor: taskState.color!,
        })
      );
  },
});

workQueueListener.startListening({
  actionCreator: taskAddedToWorkQueueSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId } = action.payload;
    const taskWorkQueue = await getWorkQueueByTaskId(taskId);
    if (taskWorkQueue) {
      listenerApi.dispatch(taskAddedToWorkQueue({ taskWorkQueue }));
    }
  },
});

workQueueListener.startListening({
  actionCreator: taskRemovedFromWorkQueueSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId } = action.payload;
    listenerApi.dispatch(taskRemovedFromWorkQueue({ taskId }));
  },
});

workQueueListener.startListening({
  actionCreator: logOnAssetAsync.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(loadWorkQueueAsync());
  },
});

export default workQueueListener;
