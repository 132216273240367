import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  JsonHubProtocol,
} from "@microsoft/signalr";
import { AnyAction, Dispatch } from "redux";
import { env } from "../../../../env";
import { selectIsAuthorized } from "../../../../store/user/authSlice";
import RetryPolicy from "../../retryPolicy";
import {
  taskAddedToWorkQueueSocketEvent,
  taskRemovedFromWorkQueueSocketEvent,
  workQueueConnectionStatusChanged,
} from "../../../../store/sockets/manufacturing-execution/workQueueSlice";

export interface TaskAddedToWorkQueueSocketDto {
  taskId: string;
}

export interface TaskRemovedFromWorkQueueSocketDto {
  taskId: string;
}

interface IWorkQueueAssetGroupService {
  init(): void;
  connect(dispatch: Dispatch<AnyAction>, getState: any): void;
  disconnect(): void;
  subscribe(dispatch: Dispatch<AnyAction>, getState: any): void;
  unsubscribe(): void;
  joinAssetGroup(assetId: string): void;
  leaveAssetGroup(assetId: string): void;
}

const workQueueHubUrl = `${env.REACT_APP_MES_API_URL}/manufacturing-execution/work-queue`;

const retryPolicy = new RetryPolicy();

const protocol = new JsonHubProtocol();

const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options: IHttpConnectionOptions = {
  transport,
};

export class WorkQueueAssetGroupService implements IWorkQueueAssetGroupService {
  public connectionWorkQueueHub: HubConnection | undefined;
  private curAssetId: string | undefined;

  constructor() {
    this.init();
  }

  init() {
    this.connectionWorkQueueHub = new HubConnectionBuilder()
      .withUrl(workQueueHubUrl, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect(retryPolicy)
      .build();
  }

  async start(dispatch: Dispatch<AnyAction>) {
    try {
      await this.connectionWorkQueueHub?.start().then(async (a) => {
        dispatch(
          workQueueConnectionStatusChanged(HubConnectionState.Connected)
        );
      });
    } catch (error) {
      console.error(error);
    }
  }

  async connect(dispatch: Dispatch<AnyAction>, getState: any) {
    if (
      this.connectionWorkQueueHub?.state !== HubConnectionState.Disconnected
    ) {
      this.unsubscribe();
      this.init();
      this.subscribe(dispatch, getState());
    }

    await this.start(dispatch);

    this.connectionWorkQueueHub?.onreconnecting((error) => {
      console.error(error);
      dispatch(
        workQueueConnectionStatusChanged(HubConnectionState.Reconnecting)
      );
    });

    this.connectionWorkQueueHub?.onreconnected((connectionId) => {
      this.curAssetId !== undefined && this.joinAssetGroup(this.curAssetId);
      dispatch(workQueueConnectionStatusChanged(HubConnectionState.Connected));
    });

    this.connectionWorkQueueHub?.onclose(async (error) => {
      error && console.error(error);
      const isAuthorized = selectIsAuthorized(getState());
      if (isAuthorized) {
        //STILL LOGGED IN
        await this.start(dispatch);
      } else {
        dispatch(
          workQueueConnectionStatusChanged(HubConnectionState.Disconnected)
        );
      }
    });
  }

  disconnect() {
    this.unsubscribe();
    this.connectionWorkQueueHub?.stop();
  }

  subscribe(dispatch: Dispatch<AnyAction>, getState: any) {
    this.connectionWorkQueueHub?.on("task-added-to-work-queue", (message) => {
      if (message != null) {
        dispatch(taskAddedToWorkQueueSocketEvent(message));
      }
    });
    this.connectionWorkQueueHub?.on(
      "task-removed-from-work-queue",
      (message) => {
        if (message != null) {
          dispatch(taskRemovedFromWorkQueueSocketEvent(message));
        }
      }
    );
  }

  async joinAssetGroup(assetId: string): Promise<void> {
    this.curAssetId = assetId;
    if (this.connectionWorkQueueHub?.state === HubConnectionState.Connected) {
      this.connectionWorkQueueHub?.invoke("JoinAssetGroup", assetId);
    }
  }

  leaveAssetGroup = (assetId: string) => {
    if (this.connectionWorkQueueHub?.state === HubConnectionState.Connected) {
      this.connectionWorkQueueHub?.invoke("LeaveAssetGroup", assetId);
    }
  };

  unsubscribe = () => {
    this.connectionWorkQueueHub?.off("task-added-to-work-queue");
    this.connectionWorkQueueHub?.off("task-removed-from-work-queue");
  };
}
