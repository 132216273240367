import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { AsyncValue, RootState } from "../../";
import i18n from "../../../i18n";
import { WorkQueueDto } from "../../../services/httpService";
import { getWorkQueue } from "../../../services/main/tasksService";
import { selectSelectedAssetId } from "../../user/assetSelectionSlice";

export interface WorkQueue extends WorkQueueDto {}
export interface WorkQueueTree extends WorkQueue {
  subRows?: Array<WorkQueue>;
}

export interface WorkQueueState {
  workQueue: AsyncValue<Array<WorkQueue>>;
  selectedTaskId: string | undefined;
}

export const initialState: WorkQueueState = {
  workQueue: {
    value: [],
    pending: false,
  },
  selectedTaskId: undefined,
};

export const loadWorkQueueAsync = createAsyncThunk(
  "production/loadWorkQueueAsync",
  async (_, { getState, dispatch }) => {
    const selectedAssetId = selectSelectedAssetId(getState() as RootState);
    if (selectedAssetId) {
      const result = await getWorkQueue(selectedAssetId);
      return result;
    } else {
      return [];
    }
  }
);

export const workQueueSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    taskSelected: (state, action: PayloadAction<string>) => {
      state.selectedTaskId = action.payload;
    },
    taskSelectionCleared: (state, action: PayloadAction<void>) => {
      state.selectedTaskId = undefined;
    },
    taskStateChanged: (
      state,
      action: PayloadAction<{
        taskId: string;
        taskStateName: string;
        taskStateColor: string;
      }>
    ) => {
      const { taskId, taskStateName, taskStateColor } = action.payload;
      state.workQueue.value = state.workQueue.value.map((wq) =>
        wq.taskId === taskId
          ? {
              ...wq,
              taskStateName: taskStateName,
              taskStateColor: taskStateColor,
            }
          : wq
      );
    },
    quantityGoodOnTaskChanged: (
      state,
      action: PayloadAction<{ taskId: string; quantity: number }>
    ) => {
      const { taskId, quantity } = action.payload;
      state.workQueue.value = state.workQueue.value.map((wq) =>
        wq.taskId === taskId ? { ...wq, quantityProduced: quantity } : wq
      );
    },
    quantityBadOnTaskChanged: (
      state,
      action: PayloadAction<{ taskId: string; quantity: number }>
    ) => {
      const { taskId, quantity } = action.payload;
      state.workQueue.value = state.workQueue.value.map((wq) =>
        wq.taskId === taskId ? { ...wq, quantityRejected: quantity } : wq
      );
    },
    taskRemovedFromWorkQueue: (
      state,
      action: PayloadAction<{ taskId: string }>
    ) => {
      const { taskId } = action.payload;
      state.workQueue.value = state.workQueue.value.filter(
        (t) => t.taskId !== taskId
      );
    },
    taskAddedToWorkQueue: (
      state,
      action: PayloadAction<{ taskWorkQueue: WorkQueueDto }>
    ) => {
      const { taskWorkQueue } = action.payload;
      if (
        !state.workQueue.value.some((t) => t.taskId === taskWorkQueue.taskId)
      ) {
        state.workQueue.value = [...state.workQueue.value, taskWorkQueue];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadWorkQueueAsync.pending, (state, action) => {
        state.workQueue.pending = true;
      })
      .addCase(loadWorkQueueAsync.fulfilled, (state, action) => {
        state.workQueue.value = action.payload!;
        state.workQueue.pending = false;
      })
      .addCase(loadWorkQueueAsync.rejected, (state, action) => {
        state.workQueue.value = [];
        state.workQueue.pending = false;
      });
  },
});

export const {
  taskSelected,
  taskSelectionCleared,
  taskStateChanged,
  quantityGoodOnTaskChanged,
  quantityBadOnTaskChanged,
  taskAddedToWorkQueue,
  taskRemovedFromWorkQueue,
} = workQueueSlice.actions;

//NESTING WORK QUEUE FOR TREE TABLE
const nest = function (seq: Array<WorkQueue>, keys: Array<string>) {
  if (!keys.length) return seq;
  const first = keys[0];
  const rest = keys.slice(1);
  return _.mapValues(_.groupBy(seq, first), function (value) {
    return nest(value, rest);
  });
};

const workQueueTreeDt = (workQueue: Array<WorkQueue>) => {
  const nested = nest(workQueue, ["workOrderId"]);
  const nestedArray = Object.entries(nested).map((e) => ({
    workOrderId: e[0] === "null" ? i18n.t("NoMoId") : e[0],
    workOrderName: workQueue.find((wq) => wq.workOrderId === e[0])
      ?.workOrderName,
    subRows: e[1] as unknown as Array<WorkQueueTree>,
  })) as Array<WorkQueueTree>;

  return nestedArray;
};
//

export const selectSelectedTaskId = createSelector(
  (state: RootState) => state.main.workQueue.selectedTaskId,
  (selectedTask) => selectedTask
);

export const selectWorkQueue = createSelector(
  (state: RootState) => state.main.workQueue.workQueue.value,
  (workQueue) => workQueue
);

export const selectWorkQueueTree = createSelector(
  selectWorkQueue,
  (workQueue) => workQueueTreeDt(workQueue)
);

export const selectSelectedWorkQueue = createSelector(
  selectWorkQueue,
  selectSelectedTaskId,
  (workQueue, selectedTaskId) =>
    workQueue.find((wq) => wq.taskId === selectedTaskId)
);

export const selectWorkQueuePending = createSelector(
  (state: RootState) => state.main.workQueue.workQueue.pending,
  (workQueuePending) => workQueuePending
);

export default workQueueSlice.reducer;
