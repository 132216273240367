import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  TaskAddedToWorkQueueSocketDto,
  TaskRemovedFromWorkQueueSocketDto,
} from "../../../services/sockets/hub-services/manufacturing-execution/workQueueAssetGroupService";

export interface WorkQueueState {
  connectionStatus: HubConnectionState;
}

export const initialState: WorkQueueState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const workQueueSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    workQueueConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    taskAddedToWorkQueueSocketEvent: (
      state,
      action: PayloadAction<TaskAddedToWorkQueueSocketDto>
    ) => {},
    taskRemovedFromWorkQueueSocketEvent: (
      state,
      action: PayloadAction<TaskRemovedFromWorkQueueSocketDto>
    ) => {},
  },
});

export const {
  workQueueConnectionStatusChanged,
  taskAddedToWorkQueueSocketEvent,
  taskRemovedFromWorkQueueSocketEvent,
} = workQueueSlice.actions;

export const selectManufacturingExecutionWorkQueueConnectionStatus =
  createSelector(
    (state: RootState) =>
      state.sockets.manufacturingExecution.workQueue.connectionStatus,
    (connectionStatus) => connectionStatus
  );

export default workQueueSlice.reducer;
