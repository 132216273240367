import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
} from "@microsoft/signalr";
import { AnyAction, Dispatch } from "redux";
import { env } from "../../../../env";
import {
  taskCancelledSocketEvent,
  taskReleasedSocketEvent,
  taskRequeuedSocketEvent,
  taskRestoredSocketEvent,
  tasksConnectionStatusChanged,
} from "../../../../store/sockets/production-planning/tasksSlice";
import { selectIsAuthorized } from "../../../../store/user/authSlice";
import RetryPolicy from "../../retryPolicy";

export interface TaskReleasedSocketDto {
  taskId: string;
  taskStateId: string;
}

export interface TaskCancelledSocketDto {
  taskId: string;
  taskStateId: string;
}

export interface TaskRestoredSocketDto {
  taskId: string;
  taskStateId: string;
}

export interface TaskRequeuedSocketDto {
  taskId: string;
  taskStateId: string;
}

interface ITasksAssetGroupService {
  init(): void;
  connect(dispatch: Dispatch<AnyAction>, getState: any): void;
  disconnect(): void;
  subscribe(dispatch: Dispatch<AnyAction>, getState: any): void;
  unsubscribe(): void;
  joinAssetGroup(assetId: string): void;
  leaveAssetGroup(assetId: string): void;
}

const assetsHubUrl = `${env.REACT_APP_MES_API_URL}/production-planning/tasks`;

const retryPolicy = new RetryPolicy();

const protocol = new JsonHubProtocol();

const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options = {
  transport,
};

export class TasksAssetGroupService implements ITasksAssetGroupService {
  public connectionTasksHub: HubConnection | undefined;
  private curAssetId: string | undefined;

  constructor() {
    this.init();
  }

  init() {
    this.connectionTasksHub = new HubConnectionBuilder()
      .withUrl(assetsHubUrl, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect(retryPolicy)
      .build();
  }

  async start(dispatch: Dispatch<AnyAction>) {
    try {
      await this.connectionTasksHub?.start().then(async (a) => {
        dispatch(tasksConnectionStatusChanged(HubConnectionState.Connected));
      });
    } catch (error) {
      console.error(error);
    }
  }

  async connect(dispatch: Dispatch<AnyAction>, getState: any) {
    if (this.connectionTasksHub?.state !== HubConnectionState.Disconnected) {
      this.unsubscribe();
      this.init();
      this.subscribe(dispatch, getState());
    }

    await this.start(dispatch);

    this.connectionTasksHub?.onreconnecting((error) => {
      console.error(error);
      dispatch(tasksConnectionStatusChanged(HubConnectionState.Reconnecting));
    });

    this.connectionTasksHub?.onreconnected((connectionId) => {
      this.curAssetId !== undefined && this.joinAssetGroup(this.curAssetId);
      dispatch(tasksConnectionStatusChanged(HubConnectionState.Connected));
    });

    this.connectionTasksHub?.onclose(async (error) => {
      error && console.error(error);
      const isAuthorized = selectIsAuthorized(getState());
      if (isAuthorized) {
        //STILL LOGGED IN
        await this.start(dispatch);
      } else {
        dispatch(tasksConnectionStatusChanged(HubConnectionState.Disconnected));
      }
    });
  }

  disconnect() {
    this.unsubscribe();
    this.connectionTasksHub?.stop();
  }

  subscribe(dispatch: Dispatch<AnyAction>, getState: any) {
    this.connectionTasksHub?.on("task-released", (message) => {
      if (message != null) {
        dispatch(taskReleasedSocketEvent(message));
      }
    });
    this.connectionTasksHub?.on("task-cancelled", (message) => {
      if (message != null) {
        dispatch(taskCancelledSocketEvent(message));
      }
    });
    this.connectionTasksHub?.on("task-restored", (message) => {
      if (message != null) {
        dispatch(taskRestoredSocketEvent(message));
      }
    });
    this.connectionTasksHub?.on("task-requeued", (message) => {
      if (message != null) {
        dispatch(taskRequeuedSocketEvent(message));
      }
    });
  }

  async joinAssetGroup(assetId: string): Promise<void> {
    this.curAssetId = assetId;
    if (this.connectionTasksHub?.state === HubConnectionState.Connected) {
      this.connectionTasksHub?.invoke("JoinAssetGroup", assetId);
    }
  }

  leaveAssetGroup = (assetId: string) => {
    if (this.connectionTasksHub?.state === HubConnectionState.Connected) {
      this.connectionTasksHub?.invoke("LeaveAssetGroup", assetId);
    }
  };

  unsubscribe = () => {
    this.connectionTasksHub?.off("task-released");
    this.connectionTasksHub?.off("task-cancelled");
  };
}
