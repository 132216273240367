import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Theme } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, FilterValue, Row } from "react-table";
import "simplebar-react/dist/simplebar.min.css";
import { makeStyles } from "tss-react/mui";
import TableName from "../../../../helpers/enums/tableName";
import {
  FormatDate,
  FormatDateString,
} from "../../../../helpers/tables/date/FormatDate";
import { filterDates } from "../../../../helpers/tables/date/filterDates";
import { filterMultiSelect } from "../../../../helpers/tables/date/filterMultiSelect";
import { sortDateTime } from "../../../../helpers/tables/date/sortDates";
import { FormatTaskState } from "../../../../helpers/tables/job-state/FormatJobState";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  WorkQueue,
  WorkQueueTree,
  selectWorkQueue,
  selectWorkQueuePending,
  selectWorkQueueTree,
  taskSelected,
  taskSelectionCleared,
} from "../../../../store/main/work-queue/workQueueSlice";
import {
  selectSelectedAsset,
  selectSelectedAssetId,
} from "../../../../store/user/assetSelectionSlice";
import TableComponent from "../../../common/table/TableComponent";
import JobStateFlatColumnFilter from "./work-orders-table/JobStateFlatColumnFilterComponent";
import JobStateTreeColumnFilter from "./work-orders-table/JobStateTreeColumnFilterComponent";
import ScheduledAssetNameFlatColumnFilter from "./work-orders-table/ScheduledAssetNameFlatColumnFilterComponent";
import ScheduledAssetNameTreeColumnFilter from "./work-orders-table/ScheduledAssetNameTreeColumnFilterComponent";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100%",
    maxHeight: "100%",
    width: "calc(100% - 13rem)",
    maxWidth: "calc(100% - 13rem)",
    paddingRight: "0.5rem",
  },
}));

interface SelectWorkOrderComponentProps {
  viewType: "tree" | "flat";
}

const SelectWorkOrderComponent: React.FC<SelectWorkOrderComponentProps> = ({
  viewType,
}) => {
  const dispatch = useAppDispatch();
  const selectedAssetId = useAppSelector(selectSelectedAssetId);
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const workQueue = useAppSelector(selectWorkQueue);
  const workQueueTree = useAppSelector(selectWorkQueueTree);
  const workQueuePending = useAppSelector(selectWorkQueuePending);
  const { classes } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(taskSelectionCleared());
    };
  }, [dispatch, selectedAssetId]);

  const defaultFilterRow = useCallback(
    (row: Row<WorkQueueTree>, id: Array<string>, filterValue: FilterValue) => {
      if (row.subRows.length > 0) {
        return row.subRows.some((row) =>
          defaultFilterRow(row, id, filterValue)
        );
      } else {
        let rowValue = row.values[id[0]];
        rowValue =
          rowValue instanceof Date
            ? FormatDateString(rowValue, t)
            : rowValue !== undefined && rowValue !== null
            ? rowValue.toString()
            : "-----";

        return filterValue instanceof Array
          ? filterValue.includes("")
            ? true
            : filterValue.includes(rowValue)
          : rowValue.toUpperCase().includes(filterValue.toUpperCase());
      }
    },
    [t]
  );

  const defaultFilter = useCallback(
    (
      rows: Array<Row<WorkQueueTree>>,
      id: Array<string>,
      filterValue: FilterValue
    ) => {
      return rows.filter((row) => {
        return defaultFilterRow(row, id, filterValue);
      });
    },
    [defaultFilterRow]
  );

  const initialState = useMemo(
    () => ({
      filters: [
        {
          id: "scheduledAssetName",
          value: [selectedAsset?.name],
        },
      ],
      sortBy: [
        {
          id: "scheduledStartAt",
          desc: true,
        },
        { id: "priority", desc: true },
      ],
    }),
    [selectedAsset]
  );

  const columnsTree: Array<Column<WorkQueueTree>> = useMemo(
    () => [
      {
        id: "expander", // Make sure it has an ID
        disableSortBy: true,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              style={{
                marginLeft: `${row.depth * 2}rem`,
                cursor: "auto",
              }}
            >
              {row.isExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
            </span>
          ) : null,
      },
      {
        Header: t("workOrderId").toString(),
        id: "workOrderId",
        accessor: "workOrderId",
        filter: defaultFilter,
      },
      {
        Header: t("workOrderName").toString(),
        id: "workOrderName",
        accessor: "workOrderName",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("taskId").toString(),
        id: "taskId",
        accessor: "taskId",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("taskName").toString(),
        id: "taskName",
        accessor: "taskName",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("taskStateName").toString(),
        id: "taskStateName",
        accessor: "taskStateName",
        Filter: JobStateTreeColumnFilter,
        filter: defaultFilter,
        Cell: (cell) =>
          FormatTaskState(
            cell.value ?? "",
            cell.row.original.taskStateColor ?? "transparent",
            t
          ),
        disableSortBy: true,
      },
      {
        Header: t("description").toString(),
        id: "taskDescription",
        accessor: "taskDescription",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("priority").toString(),
        id: "priority",
        accessor: "priority",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("quantityRequired").toString(),
        id: "quantityRequired",
        accessor: "quantityRequired",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("quantityProduced").toString(),
        id: "quantityProduced",
        accessor: "quantityProduced",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("quantityRejected").toString(),
        id: "quantityRejected",
        accessor: "quantityRejected",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("notes").toString(),
        id: "notes",
        accessor: "notes",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("scheduledStartAt").toString(),
        id: "scheduledStartAt",
        accessor: "scheduledStartAt",
        Cell: (cell) =>
          cell.row.depth === 1 ? FormatDate(cell.value, t) : null,
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("scheduledFinishAt").toString(),
        id: "scheduledFinishAt",
        accessor: "scheduledFinishAt",
        Cell: (cell) =>
          cell.row.depth === 1 ? FormatDate(cell.value, t) : null,
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("executionStartedAt").toString(),
        id: "executionStartedAt",
        accessor: "executionStartedAt",
        Cell: (cell) =>
          cell.row.depth === 1 ? FormatDate(cell.value, t) : null,
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("executionFinishedAt").toString(),
        id: "executionFinishedAt",
        accessor: "executionFinishedAt",
        Cell: (cell) =>
          cell.row.depth === 1 ? FormatDate(cell.value, t) : null,
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("scheduledAssetId").toString(),
        id: "scheduledAssetId",
        accessor: "scheduledAssetId",
        disableSortBy: true,
        filter: defaultFilter,
      },
      {
        Header: t("scheduledAssetName").toString(),
        id: "scheduledAssetName",
        accessor: "scheduledAssetName",
        disableSortBy: true,
        Filter: ScheduledAssetNameTreeColumnFilter,
        filter: defaultFilter,
      },
      {
        Header: t("executionAssetId").toString(),
        id: "executionAssetId",
        accessor: "executionAssetId",
        disableSortBy: true,
        filter: defaultFilter,
      },
      {
        Header: t("executionAssetName").toString(),
        id: "executionAssetName",
        accessor: "executionAssetName",
        disableSortBy: true,
        filter: defaultFilter,
      },
      {
        Header: t("itemName").toString(),
        id: "itemName",
        accessor: "itemName",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("itemDescription").toString(),
        id: "itemDescription",
        accessor: "itemDescription",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("uomName").toString(),
        id: "uomName",
        accessor: "uomName",
        filter: defaultFilter,
        disableSortBy: true,
      },
      {
        Header: t("abbreviation").toString(),
        id: "abbreviation",
        accessor: "abbreviation",
        filter: defaultFilter,
        disableSortBy: true,
      },
    ],
    [t, defaultFilter]
  );

  const columnsFlat: Array<Column<WorkQueue>> = useMemo(
    () => [
      {
        Header: t("workOrderId").toString(),
        id: "workOrderId",
        accessor: "workOrderId",
      },
      {
        Header: t("workOrderName").toString(),
        id: "workOrderName",
        accessor: "workOrderName",
      },
      {
        Header: t("taskId").toString(),
        id: "taskId",
        accessor: "taskId",
      },
      {
        Header: t("taskName").toString(),
        id: "taskName",
        accessor: "taskName",
      },
      {
        Header: t("taskStateName").toString(),
        id: "taskStateName",
        accessor: "taskStateName",
        Filter: JobStateFlatColumnFilter,
        Cell: (cell) =>
          FormatTaskState(
            cell.value ?? "",
            cell.row.original.taskStateColor ?? "transparent",
            t
          ),
      },
      {
        Header: t("description").toString(),
        id: "taskDescription",
        accessor: "taskDescription",
      },
      {
        Header: t("priority").toString(),
        id: "priority",
        accessor: "priority",
      },
      {
        Header: t("quantityRequired").toString(),
        id: "quantityRequired",
        accessor: "quantityRequired",
      },
      {
        Header: t("quantityProduced").toString(),
        id: "quantityProduced",
        accessor: "quantityProduced",
      },
      {
        Header: t("quantityRejected").toString(),
        id: "quantityRejected",
        accessor: "quantityRejected",
      },
      {
        Header: t("notes").toString(),
        id: "notes",
        accessor: "notes",
      },
      {
        Header: t("scheduledStartAt").toString(),
        id: "scheduledStartAt",
        accessor: "scheduledStartAt",
        Cell: (cell) => FormatDate(cell.value, t),
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
        sortType: sortDateTime,
      },
      {
        Header: t("scheduledFinishAt").toString(),
        id: "scheduledFinishAt",
        accessor: "scheduledFinishAt",
        Cell: (cell) => FormatDate(cell.value, t),
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
        sortType: sortDateTime,
      },
      {
        Header: t("executionStartedAt").toString(),
        id: "executionStartedAt",
        accessor: "executionStartedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
        sortType: sortDateTime,
      },
      {
        Header: t("executionFinishedAt").toString(),
        id: "executionFinishedAt",
        accessor: "executionFinishedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
        sortType: sortDateTime,
      },
      {
        Header: t("scheduledAssetId").toString(),
        id: "scheduledAssetId",
        accessor: "scheduledAssetId",
      },
      {
        Header: t("scheduledAssetName").toString(),
        id: "scheduledAssetName",
        accessor: "scheduledAssetName",
        Filter: ScheduledAssetNameFlatColumnFilter,
        filter: (rows, id, filterValue) =>
          filterMultiSelect(rows, id, filterValue, t),
      },
      {
        Header: t("executionAssetId").toString(),
        id: "executionAssetId",
        accessor: "executionAssetId",
      },
      {
        Header: t("executionAssetName").toString(),
        id: "executionAssetName",
        accessor: "executionAssetName",
      },
      {
        Header: t("itemName").toString(),
        id: "itemName",
        accessor: "itemName",
      },
      {
        Header: t("itemDescription").toString(),
        id: "itemDescription",
        accessor: "itemDescription",
      },
      {
        Header: t("uomName").toString(),
        id: "uomName",
        accessor: "uomName",
      },
      {
        Header: t("abbreviation").toString(),
        id: "abbreviation",
        accessor: "abbreviation",
      },
    ],
    [t]
  );

  const handleRowClick = (row: Row<WorkQueue>) => {
    if (!row.isSelected) {
      dispatch(taskSelected(row.original.taskId));
    } else {
      dispatch(taskSelectionCleared());
    }
  };

  return (
    <div className={classes.root}>
      {viewType === "tree" && (
        <TableComponent
          name={TableName.WORK_QUEUE_TREE}
          data={workQueueTree}
          columns={columnsTree}
          isLoading={workQueuePending}
          onClick={handleRowClick}
          editable
          initialState={initialState}
        />
      )}
      {viewType === "flat" && (
        <TableComponent
          name={TableName.WORK_QUEUE}
          data={workQueue}
          columns={columnsFlat}
          isLoading={workQueuePending}
          onClick={handleRowClick}
          editable
          initialState={initialState}
        />
      )}
    </div>
  );
};

export default SelectWorkOrderComponent;
