import { FormControl, MenuItem, Select } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HeaderProps } from "react-table";
import { WorkQueue } from "../../../../../store/main/work-queue/workQueueSlice";
import { selectSelectedAsset } from "../../../../../store/user/assetSelectionSlice";
import { useAppSelector } from "../../../../../store/hooks";

const ScheduledAssetNameTreeColumnFilter: React.FC<HeaderProps<WorkQueue>> = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const { t } = useTranslation();
  const selectedAsset = useAppSelector(selectSelectedAsset);

  const options: string[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    const options = new Array();
    selectedAsset && options.push(selectedAsset.name!);
    preFilteredRows.forEach((wo: any) => {
      // use originalSubRows is workaround to avoid missing subRows
      wo.originalSubRows?.forEach((task) => {
        if (!options.some((o) => o === task.scheduledAssetName)) {
          options.push(task.scheduledAssetName!);
        }
      });
    });
    return [...options];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFilteredRows]);

  React.useEffect(() => {
    setFilter([selectedAsset?.name]);
  }, [selectedAsset]);

  return (
    <FormControl fullWidth>
      {options && (
        <Select
          value={filterValue || []}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          displayEmpty
          variant="standard"
          multiple
        >
          <MenuItem value="">{t("All")}</MenuItem>
          {options.map((option, i) => (
            <MenuItem
              key={i}
              value={option}
              style={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              {t(option)}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

export default ScheduledAssetNameTreeColumnFilter;
