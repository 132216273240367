import GridLayout from "react-grid-layout";

export const defaultNewUniversalComponent = `
({ asset, user, runningTask }) => {
  //PLACE FOR OBJECT DESTRUCTURING
  const {
    useTranslation,
    useMqttValue,
    useSnackbar,
    useLocalStorage,
    useLocalStorageState,
  } = hooks;  
  const { success, warning, info, error } = snackbar;
  const { styled, Paper, Typography, Box, Divider, Button, TextField } =
    materialUI;
  const {    UtilSummaryChartComponent,
    OEEChartComponent,
    OEESimpleChartComponent,
    ProductionSummaryChartComponent,
    UtilDowntimesChartComponent,
    UtilTimelineChartComponent} = chartComponents;

  //PLACE FOR HOOKs
  const [assetDesc, setAssetDesc] = React.useState();
  const { t, i18n } = useTranslation();
  const params = React.useMemo(
    () => ({ ent_id: asset ? asset.id.toString() : "" }),
    [asset]
  );

  //HTTP REQUESTS
  const http = axios.create({
    baseURL: mesApiUrl,
    timeout: 10000,
    headers: { Authorization: "Bearer " + user.token },
  });

  //PLACE FOR STYLED COMPONENTS
  const Root = React.useMemo(
    () =>
      styled(Paper)(({ theme }) => ({
        height: "100%",
        overflow: "auto",
        padding: "0.5rem",
        boxSizing: "border-box",
      })),
    []
  );

  const Title = React.useMemo(
    () =>
      styled("h1")(({ theme }) => ({
        fontSize: "1.8rem",
      })),
    []
  );

  //YOUR COMPONENT LOGIC

  //TODO...

  //RENDER
  return (
    <Root elevation={2} square>
      <Title>{t("EditableComponentTitle")}</Title>
      <div>
        <Typography variant="subtitle1">
          Witaj {user.userDesc}! Znajdujesz się na stanowisku {asset.name}.
        </Typography>
        <Typography variant="subtitle2">
          Oto przykładowe użycie edytowalnego komponentu.
        </Typography>
      </div>
    </Root>
  );
};
`;

export enum OverviewGridItemKey {
  EDITABLE_COMPONENT = "EDITABLE_COMPONENT",
  OEE_CHART = "OEE_CHART",
  OEE_SIMPLE_CHART = "OEE_SIMPLE_CHART",
  UTILIZATION_SUMMARY_CHART = "UTILIZATION_SUMMARY_CHART",
  PRODUCTION_SUMMARY_CHART = "PRODUCTION_SUMMARY_CHART",
  UTILIZATION_DOWNTIMES_CHART = "UTILIZATION_DOWNTIMES_CHART",
  UTILIZATION_TIMELINE_CHART = "UTILIZATION_TIMELINE_CHART",
}

export const defaultLayouts = {
  lg: [
    { i: OverviewGridItemKey.OEE_CHART, x: 0, y: 0, w: 6, h: 10 },
    {
      i: OverviewGridItemKey.UTILIZATION_SUMMARY_CHART,
      x: 6,
      y: 0,
      w: 5,
      h: 10,
    },
    {
      i: OverviewGridItemKey.PRODUCTION_SUMMARY_CHART,
      x: 11,
      y: 0,
      w: 5,
      h: 10,
    },
    {
      i: OverviewGridItemKey.UTILIZATION_DOWNTIMES_CHART,
      x: 0,
      y: 10,
      w: 6,
      h: 11,
    },
    {
      i: OverviewGridItemKey.UTILIZATION_TIMELINE_CHART,
      x: 6,
      y: 10,
      w: 10,
      h: 11,
    },
  ] as Array<GridLayout.Layout>,
  md: [
    { i: OverviewGridItemKey.OEE_CHART, x: 0, y: 0, w: 6, h: 10 },
    {
      i: OverviewGridItemKey.UTILIZATION_SUMMARY_CHART,
      x: 6,
      y: 0,
      w: 6,
      h: 10,
    },
    {
      i: OverviewGridItemKey.PRODUCTION_SUMMARY_CHART,
      x: 0,
      y: 10,
      w: 6,
      h: 9,
    },
    {
      i: OverviewGridItemKey.UTILIZATION_DOWNTIMES_CHART,
      x: 6,
      y: 10,
      w: 6,
      h: 9,
    },
    {
      i: OverviewGridItemKey.UTILIZATION_TIMELINE_CHART,
      x: 0,
      y: 19,
      w: 12,
      h: 8,
    },
  ] as Array<GridLayout.Layout>,
  sm: [
    { i: OverviewGridItemKey.OEE_CHART, x: 0, y: 0, w: 8, h: 9 },
    {
      i: OverviewGridItemKey.UTILIZATION_SUMMARY_CHART,
      x: 0,
      y: 9,
      w: 8,
      h: 9,
    },
    {
      i: OverviewGridItemKey.PRODUCTION_SUMMARY_CHART,
      x: 0,
      y: 18,
      w: 8,
      h: 8,
    },
    {
      i: OverviewGridItemKey.UTILIZATION_DOWNTIMES_CHART,
      x: 0,
      y: 26,
      w: 8,
      h: 7,
    },
    {
      i: OverviewGridItemKey.UTILIZATION_TIMELINE_CHART,
      x: 0,
      y: 33,
      w: 8,
      h: 8,
    },
  ] as Array<GridLayout.Layout>,
  xs: [
    { i: OverviewGridItemKey.OEE_CHART, x: 0, y: 0, w: 6, h: 7 },
    {
      i: OverviewGridItemKey.UTILIZATION_SUMMARY_CHART,
      x: 0,
      y: 7,
      w: 6,
      h: 7,
    },
    {
      i: OverviewGridItemKey.PRODUCTION_SUMMARY_CHART,
      x: 0,
      y: 14,
      w: 6,
      h: 7,
    },
    {
      i: OverviewGridItemKey.UTILIZATION_DOWNTIMES_CHART,
      x: 0,
      y: 21,
      w: 6,
      h: 7,
    },
    {
      i: OverviewGridItemKey.UTILIZATION_TIMELINE_CHART,
      x: 0,
      y: 28,
      w: 6,
      h: 7,
    },
  ] as Array<GridLayout.Layout>,
} as GridLayout.Layouts;

export const defaultItems = [
  OverviewGridItemKey.OEE_CHART,
  OverviewGridItemKey.UTILIZATION_SUMMARY_CHART,
  OverviewGridItemKey.UTILIZATION_DOWNTIMES_CHART,
  OverviewGridItemKey.PRODUCTION_SUMMARY_CHART,
  OverviewGridItemKey.UTILIZATION_TIMELINE_CHART,
];
