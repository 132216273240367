import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useLocalStorage from "../hooks/useLocalStorage";
import useLocalStorageState from "../hooks/useLocalStorageState";
import useMqttValue from "../hooks/useMqttValue";
import usePrevious from "../hooks/usePrevious";
import * as materialUI from "@mui/material";
import * as chartJS from "react-chartjs-2";
import { env } from "../../env";
import snackbarHelper from "../snackbarHelper";
import appTheme from "../../themes/appTheme";
import { decimalColorToHTMLcolor } from "../decimalColorConverter";
import useDatabaseData from "../hooks/useDatabaseData";
import useDatabaseCommand from "../hooks/useDatabaseCommand";
import {
  OEEChartComponent,
  OEESimpleChartComponent,
  ProductionSummaryChartComponent,
  UtilDowntimesChartComponent,
  UtilSummaryChartComponent,
  UtilTimelineChartComponent,
} from "@improdis/core";

const staticScope = {
  axios: axios,
  moment: moment,
  _: _,
  appTheme: appTheme,
  mesApiUrl: env.REACT_APP_MES_API_URL,
  snackbar: snackbarHelper,
  decimalColorToHTMLcolor,
  hooks: {
    useTranslation,
    useDatabaseData,
    useDatabaseCommand,
    useMqttValue,
    useLocalStorage,
    useLocalStorageState,
    usePrevious,
  },
  materialUI: materialUI,
  chartJS: chartJS,
  chartComponents: {
    UtilSummaryChartComponent,
    OEEChartComponent,
    OEESimpleChartComponent,
    ProductionSummaryChartComponent,
    UtilDowntimesChartComponent,
    UtilTimelineChartComponent,
  },
};

export default staticScope;
